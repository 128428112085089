<template>
  <div class="tags-input__wrapper">
    <el-tag
      v-for="(tag, index) in dynamicTags"
      :key="tag + index"
      :closable="true"
      :close-transition="false"
      :type="tagType"
      size="small"
      @close="handleClose(tag)"
    >
      {{ tag }}
    </el-tag>

    <input
      ref="saveTagInput"
      v-model="inputValue"
      class="form-control"
      size="mini"
      v-bind="$attrs"
      @blur="handleInputConfirm"
      @input="onInput"
      @keydown="handlePreventEnterKey"
      @keyup.enter.prevent="handleInputConfirm"
    />
  </div>
</template>

<script>
import {Tag} from 'element-ui';

export default {
  name: 'tags-input',
  inheritAttrs: false,
  components: {
    [Tag.name]: Tag,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
      description: 'List of tags',
    },
    tagType: {
      type: String,
      default: 'primary',
      description: 'Tag type (primary|danger etc)',
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
    };
  },
  methods: {
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      this.$emit('change', this.dynamicTags);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      const inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
        this.$emit('change', this.dynamicTags);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    onInput(evt) {
      this.$emit('input', evt.target.value);
    },
    handlePreventEnterKey(event) {
      // Prevent the default behavior for Enter key in forms or modals
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    },
    focusInput() {
      this.$refs.saveTagInput.focus();
    },
  },
  created() {
    this.$watch(
      'value',
      (newVal) => {
        this.dynamicTags = [...newVal];
      },
      {immediate: true},
    );
  },
};
</script>
