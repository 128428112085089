<template>
  <div class="invite-member-modal">
    <modal
      :isClose="isClose"
      :show="display"
      :showCancel="showCancel"
      :showClose="showClose"
      footerClasses="d-none"
      headerClasses="app-border-b align-items-center"
      v-on:close="closeModal"
    >
      <template v-slot:header>
        <h5 id="exampleModalLabel" class="modal-title">{{ header }}</h5>
      </template>
      <div :class="contentClass" class="mt-2 mb-5" v-html="question"></div>
      <div :class="btnClass" class="d-flex">
        <base-button
          v-if="showCancel"
          :type="cancelBtnType"
          class="border-0"
          @click.prevent="rejectHandler"
        >
          {{ cancelBtnText }}
        </base-button>
        <base-button
          :type="confirmBtnType"
          @click.prevent="acceptHandler"
        >
          {{ confirmBtnText }}
        </base-button>
      </div>
    </modal>
  </div>
</template>
<script>

const eventAccept = new Event('accept-modal');
const eventReject = new Event('reject-modal');

export default {
  props: {
    header: String,
    question: String,
    cancelBtnText: {
      type: String,
      default: 'No',
    },
    confirmBtnText: {
      type: String,
      default: 'Yes',
    },
    confirmBtnType: {
      type: String,
      default: 'primary',
    },
    cancelBtnType: {
      type: String,
      default: 'outline-primary',
    },
    contentClass: {
      type: String,
      default: '',
    },
    btnClass: {
      type: String,
      default: 'justify-content-end',
    },
    isClose: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      display: false,
      confirmData: null,
    };
  },
  methods: {
    openModal(confirmData = null) {
      this.confirmData = confirmData;
      this.display = true;
    },
    async openModalAsync(confirmData = null) {
      this.confirmData = confirmData;
      this.display = true;
      return new Promise((resolve, reject) => {
        addEventListener('accept-modal', () => {
          this.removeListeners();
          resolve('accept');
        });
        addEventListener('reject-modal', () => {
          this.removeListeners();
          reject(new Error('Reject'));
        });
      });
    },
    removeListeners: function() {
      removeEventListener('accept-modal', eventAccept);
      removeEventListener('reject-modal', eventReject);
    },
    closeModal: function() {
      setTimeout(() => {
        this.display = false;
      });
      this.$emit('on-close', this.confirmData);
    },
    acceptHandler: function() {
      dispatchEvent(eventAccept);
      this.$emit('on-accept', this.confirmData);
      this.closeModal();
    },
    rejectHandler: function() {
      dispatchEvent(eventReject);
      this.$emit('on-reject', this.confirmData);
      this.closeModal();
    },
  },
};
</script>
