<template>
  <div class="invite-member-modal">
    <modal
      :show="display"
      footerClasses="d-none"
      headerClasses="app-border-b align-items-center"
      v-on:close="displayToggle(false)"
    >
      <template v-slot:header>
        <h5 id="exampleModalLabel" class="modal-title">{{
            isTeamHiringManagers
              ? 'Invite a hiring manager'
              : 'Invite a new team member to fill an open seat'
          }}</h5>
      </template>
      <div class="mt-2 mb-3 invite-member-title">
        {{
          isTeamHiringManagers ?
            'The hiring manager will receive a welcome email with instructions on how to join your team shortly, ask them to check their spam/junk folders if they don’t see it.'
            :
            'The new team member will receive a welcome email with instructions  on how to join your team shortly, ask them to check their spam/junk folders if they don’t see it.'
        }}
      </div>
      <ValidationObserver ref="formValidator" v-slot="{ invalid, validate }">
        <form
          class="needs-validation invite-member"
          @submit.prevent="validate().then(submit)"
        >
          <div class="mb-2">
            <b>{{ isTeamHiringManagers ? 'Hiring manager’s email' : 'New team member’s email' }}</b>
          </div>
          <div>
            <base-input
              v-model="email"
              :rules="`required|email|isBusy:${usedEmails}`"
              class="hiring-manager-email"
              name="Email"
              placeholder="Email Address"
              type="email"
              @keydown="closeModalPreventHandler"
            ></base-input>
          </div>
          <div v-if="isTeamHiringManagers">
            <div class="mb-2">
              <get-number-screen
                :isTeamHiringManagers="true"
                @phone-selected="handlePhoneSelected"
                @area-code-changed="resetPhoneNumber"
              />
            </div>
          </div>

          <div v-else>
            <div class="mb-2">
              <b>Select account type</b>
            </div>
            <b-form-group class="mb-5 fw-bold d-block">
              <b-form-radio v-model="invitationType" :disabled="seatsFilled" name="invite-type" value="member_texter">
                <span class="ml-2 font-500">Member</span>
              </b-form-radio>
              <b-form-radio v-model="invitationType" :disabled="seatsFilled" name="invite-type" value="admin_texter">
                <span class="ml-2 font-500">Admin with texting</span>
              </b-form-radio>
              <b-form-radio v-model="invitationType" name="invite-type" value="admin_nonTexter">
                <span class="ml-2 font-500">Admin without texting (free seat)</span>
              </b-form-radio>
            </b-form-group>
          </div>

          <div :class="{ 'mt-4': !isTeamHiringManagers }" class="d-flex justify-content-end">
            <button
              class="btn btn-outline-primary border-0"
              @click.prevent="displayToggle(false)"
            >
              Cancel
            </button>
            <button :disabled="isTeamHiringManagers ? invalid || !phoneNumber : invalid || invitationType === ''"
                    class="btn btn-primary"
                    type="submit">
              <i class="ni ni-send"></i>
              Send Invite
            </button>
          </div>
        </form>
      </ValidationObserver>
    </modal>
  </div>
</template>
<script>
import {mapActions} from 'vuex';
import {INVITE_USER_REQUEST} from '@/store/storeActions';
import {seatRoles, seatTypes, userTypes} from '@/store/userConstants';
import GetNumberScreen from '@/components/App/User/GetNumber/GetNumberScreen.vue';

export default {
  components: {
    GetNumberScreen,
  },
  data() {
    return {
      display: false,
      email: '',
      userTypes,
      userType: userTypes.MEMBER,
      seatTypes,
      seatRoles,
      seatType: seatTypes.NON_TEXTER,
      invitationType: '',
      phoneNumber: null,
    };
  },
  props: {
    usedEmails: Array,
    seatsFilled: Boolean,
    isTeamHiringManagers: Boolean,
  },
  methods: {
    ...mapActions(
      [INVITE_USER_REQUEST],
    ),
    handlePhoneSelected(phone) {
      this.phoneNumber = phone; // Update the phoneNumber when selected
    },
    resetPhoneNumber() {
      this.phoneNumber = null; // Reset phone number
    },
    displayToggle: function(isDisplay) {
      this.display = isDisplay;
      if (isDisplay) {
        this.email = ''; // Reset email field
        this.phoneNumber = null;
      }
    },
    submit: function() {
      if (this.isTeamHiringManagers) {
        this.INVITE_USER_REQUEST({
          email: this.email,
          userType: this.userTypes.MEMBER,
          seatType: this.seatTypes.TEXTER,
          seatRole: this.seatRoles.HIRING_MANAGER,
          phoneNumber: this.phoneNumber,
        });
        this.displayToggle(false);
      } else {
        this.processInvitationType();
        this.INVITE_USER_REQUEST({
          email: this.email,
          userType: this.userType,
          seatType: this.seatType,
        });
        this.invitationType = '';
        this.displayToggle(false);
      }
    },
    processInvitationType: function() {
      switch (this.invitationType) {
        case 'member_texter':
          this.userType = this.userTypes.MEMBER;
          this.seatType = this.seatTypes.TEXTER;
          break;
        case 'admin_texter':
          this.userType = this.userTypes.ADMIN;
          this.seatType = this.seatTypes.TEXTER;
          break;
        case 'admin_nonTexter':
          this.userType = this.userTypes.ADMIN;
          this.seatType = this.seatTypes.NON_TEXTER;
          break;
      }
    },
    closeModalPreventHandler(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        this.$refs.formValidator.validate().then((isSuccess) => isSuccess && this.submit());
      }
    },
  },
};
</script>

<style lang="scss">
.invite-member .form-group {
  margin-bottom: 0px !important;
}

.hiring-manager-email .form-group {
  height: 57px;
}

.hiring-manager-email:has(.form-control.is-invalid) .form-group {
  height: 66px;
}

.invite-member-title {
  font-size: 15px;
}

.modal-header .close {
  margin: -0.6rem -1rem -1rem auto !important;
}
</style>
