<template>
  <base-input :label="label">
    <div :style="{ maxHeight: blockHeight }" class="select-wrapper">
      <div class="select-container">
        <div
          v-for="option in options"
          :key="option.label"
          :class="{ selected: option.value === value }"
          class="option"
          @click="selectItem(option.value)"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
  </base-input>
</template>
<script>
import BaseInput from '@/components/Inputs/BaseInput.vue';
import {Option, Select} from 'element-ui';

export default {
  components: {
    BaseInput,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {},
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    size: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      itemHeight: 38,
    };
  },
  methods: {
    selectItem: function(value) {
      this.$emit('input', value);
    },
  },
  computed: {
    blockHeight: function() {
      return (this.size * 38) + 'px';
    },
  },
};
</script>
<style lang="scss" scoped>

.select-wrapper {
  overflow-y: scroll;
  border: 1px solid #DEE2E6;
  border-radius: 6px;

  .select-container {
    .option {
      padding: 7px 12px;
      transition: all .35s linear;
      cursor: default;

      &:hover {
        background-color: #e5f5ff;
      }

      &.selected {
        background-color: #e5f5ff;
        color: #009FFF;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  right: 100px;
}

::-webkit-scrollbar-thumb {
  background: #435451;
  border-radius: 6px;
}
</style>
